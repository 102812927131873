<template>
  <div class="dynamic">
    <!-- 头部 -->
    <van-nav-bar fixed placeholder z-index="100" title="动态"   left-arrow  @click-left="onClickLeft">
        <template #right>
           <div class="right">
             <img src="../../assets/image/myInfo.png" alt="" class="rightIcon" @click="jumpan">
             <div class="redIcon" v-if="infoType"></div>
           </div>
        </template>
    </van-nav-bar>

    <!-- tab标签 -->
    <van-tabs v-model="active" @change="onTabChange">
      <van-tab :title="item.title" v-for="item in tabData" :key="item.type">
        <div class="kong" v-if="dynamicList.length == 0 || dynamicList == null || dynamicList == undefined">
          <img src="~@image/search-kong.png" alt="">
          <p>暂无动态内容</p>
        </div>
       <ul v-else>
        <post-list @handleshare="handleshare(item)"  @jumpDetail="jumpDetail(item)"  v-for="(item,index) in dynamicList"  @showOperation="showOperation(item,index)" :key="index" :item="item"> </post-list>
       </ul>
      </van-tab>
    </van-tabs>
    
      <van-popup :class="dataItem.postType !==3 ?'popup-operation':'popup_active'" v-if="isshow!=true"  v-model="operation" position="bottom">
            <div :class="dataItem.postType !==3?'popup_item':'active_item'" v-show="dataItem.postType !==3" @click="postCollect(dataItem,dataIndex)"  v-if="dataItem.collect !== true">
              <img src="../../assets/image/post-collect.png" alt=""/>
              <span>收藏</span>
            </div>

            <div :class="dataItem.postType !==3?'popup_item':'active_item'" v-show="dataItem.postType !==3" @click="removeCollect(dataItem,dataIndex)"  v-else>
              <img src="../../assets/image/post-collect.png" alt=""/>
              <span>取消收藏</span>
            </div>

            <div :class="dataItem.postType !==3?'popup_item':'active_item'" @click="postShield(dataItem)">
              <img src="../../assets/image/post-shield.png" alt=""/>
              <span>屏蔽</span>
            </div>

            <div :class="dataItem.postType !==3?'popup_item':'active_item'" @click="postReport(dataItem)">
              <img src="../../assets/image/post-report.png" alt=""/>
              <!-- 投诉 -->
              <span>举报</span>
            </div>
    </van-popup>

    <van-popup :class="dataItem.postType !==3 ?'popup-operation':'popup_active'" v-else v-model="operation" position="bottom" >
           <div :class="dataItem.postType !==3?'popup_item':'active_item'" v-show="dataItem.postType !==3" @click="postCollect(dataItem,dataIndex)"  v-if="dataItem.collect !== true">
              <img src="../../assets/image/post-collect.png" alt=""/>
              <span>收藏</span>
            </div>

            <div :class="dataItem.postType !==3?'popup_item':'active_item'" v-show="dataItem.postType !==3" @click="removeCollect(dataItem,dataIndex)"  v-else>
              <img src="../../assets/image/post-collect.png" alt=""/>
              <span>取消收藏</span>
            </div>

            <div :class="dataItem.postType !==3?'popup_item':'active_item'" @click="editor(dataItem)">
              <img src="../../assets/image/My-bianji.png" alt=""/>
              <span>编辑</span>
            </div>
            <div :class="dataItem.postType !==3?'popup_item':'active_item'" @click="delReport(dataItem,dataIndex)">
              <img src="../../assets/image/My-del.png" alt=""/>
              <span>删除</span>
            </div>
    </van-popup>

     
      
  </div>
</template>

<script>
import PostList from "@components/community/PostList2.vue";
import { getdynamic,getfabulous,findAllCollectByUserId,getInfo,delArticle,delBugOrSuggest} from "../../api/user";
import {Collection,removeCollect} from "../../api/community";
import qs from "qs"
import { Dialog } from 'vant';
  export default {
    components:{
      PostList,
    },
      data(){
        return{
           active:0,
           dynamicList:'',
           dataItem:'',
           userInfo:'',
           infoType:'',
           operation:false,
           isshow:false,
           dataIndex:'',
           tabData:[
             {
               title:'我的动态',
               type:0
             },
             {
               title:'我的点赞',
               type:1
             },
             {
               title:"我的收藏",
               type:2
             }
           ]
        }
        
      },
      methods:{
          //  返回
          onClickLeft() {
            this.$router.push({
              name:"My"
            })
          },
          //分享
          handleshare(item){
            let txt = "http://"+window.location.host+"/#/CommunitDetails?id="+item.id+"&type="+item.postType
            if(window.android){
                  const obj = {
                    action:'share',
                    data:{
                      type:3,
                      content:txt,
                    }
                }
                window.android.postMessage(JSON.stringify(obj));
            }else if(window.webkit){
              // ios 环境下
              const obj = {
                action: "share",
                data: {
                  type: 3,
                  content:txt,
                },
              };
              let jsonStr = JSON.stringify(obj);
              window.webkit.messageHandlers.sendMessageForVue.postMessage(jsonStr);
            }
          },
    
    //取消收藏
      async removeCollect(item,index){
         const info = {
           targetId:item.id,
           type:item.postType
         }
         const result = await removeCollect(qs.stringify(info))
         if(result.code === 0){
           this.$toast("取消收藏!")
           this.operation = false
           this.dynamicList[index].collect = false
           this.active = 2
           this.onTabChange()
         }else{
           this.$toast(result.msg)
           this.operation = false
         }
      },


         showOperation(item,index){
            this.operation = true
            this.dataItem = item
            this.dataIndex = index
            if(item.userid == this.userInfo.userId){
              this.isshow = true
            }else{
              this.isshow = false
            }
          },
          //跳转消息页面
          jumpan(){
             if(window.android){
                let obj = {
                  action:'message'
                }
                window.android.postMessage(JSON.stringify(obj));
             }else if(window.webkit){
               let obj = {
                  action:'message'
                }
                window.webkit.messageHandlers.sendMessageForVue.postMessage(JSON.stringify(obj));
             }
          },
          
            jumpDetail(item){
                this.$router.push({
                  name:'CommunitDetails',
                  query:{
                    id:item.id,
                    type:item.postType,
                    tabType:this.active,
                    pageType:2,
                  }
                })
            },

          //收藏
          async postCollect(item,index){
            let obj = ""
            obj = item
            const info = {
              collectType:1,
              targetId:obj.id,
              type:obj.postType,
              userId:obj.userid
            }
            const result = await Collection(qs.stringify(info))
            if(result.code === 0 ){
              this.$toast(result.msg)
              this.operation = false
              this.dynamicList[index].collect = true
            }
          },


           //删除
          async del(item,index){
              const info = {
                    dismainId:item.id
              } 
              const res = await delArticle(info)
              if(res.code === 0 ){
                  this.$toast("删除成功！")
                  this.dynamicList.splice(index,1)
              }    
            },

            async delbug(item,index){
                const info = {
                  id : item.id
                }
                const res = await delBugOrSuggest(qs.stringify(info))
                if(res.code === 0 ){
                  this.$toast("删除成功！")
                  this.dataList.splice(index,1)
                }    
            },
            
            delReport(item,index){
                this.operation = false
                Dialog.confirm({
                  title: '删除',
                  message: '是否删除该帖子吗？',
                  confirmButtonText:'删除',
                  confirmButtonColor:'#4588FF',
                })
                  .then(() => {
                    // on confirm
                  if(item.postType == 1 || item.postType == 2){
                      this.del(item,index)
                  }else{
                      this.delbug(item,index)
                  }
                  })
                  .catch(() => {
                    // on cancel 
                  });
          },


          //编辑
          editor(item){
             sessionStorage.setItem("pageType","2")
             sessionStorage.setItem("tabType",this.active)
             this.$router.push(`/addposts2/${item.postType}?id=${item.id}`)
          },
          //举报
           postReport(item){
              sessionStorage.setItem("tabType",this.active)
              this.$router.push({
                name:'complaint',
                query:{
                    id:item.id,
                    type:item.postType,
                    pageType:1,
                }
              })
            },

          //屏蔽
            async postShield(item){
              let obj = ""
              obj = item
              const info = {
                collectType:2,
                targetId:obj.id,
                type:obj.postType,
                userId:obj.userid
              }
              const result = await Collection(qs.stringify(info))
              if(result.code === 0 ){
                this.$toast("屏蔽成功！")
                 this.operation = false
                 this.$router.go(0)
              }
            },
         //获取是否有消息
         async getinfo(){
            this.userInfo= JSON.parse(localStorage.getItem("userInfo"))
            console.log(this.userInfo.userId)
            const data = {
              id:this.userInfo.userId
            }
            const result = await getInfo(data)
            if(result.code === 0 ){
              console.log(result.data)
              if(result.data == 0 || result.data == null || result.data == undefined){
                this.infoType = false
              }else{
                this.infoType = true
              }
            }
         },
         
         onTabChange(){
           this.dynamicList = []
           this.getData()
         },
         //标签切换重新获取数据
         async getData(){
           this.$toast.loading({
            message: '加载中...',
            forbidClick: true,
          })
            if(this.tabData[this.active].type == 0){
              const data = {
                pageNum:1,
                pageSize:10,
              }
              const result = await getdynamic(qs.stringify(data))
              if(result.code === 0 ){
                this.$toast.clear()

                result.data.list.map(item =>{
                   if(item.pics.length >0){
                     item.pics =  item.pics.slice(0,4)
                   }
                })

                this.dynamicList = result.data.list
               
              }
            }else if(this.tabData[this.active].type == 1){
              const data = {
                pageNum:1,
                pageSize:10,
              }
                const result = await getfabulous(qs.stringify(data))
              if(result.code === 0 ){
                this.$toast.clear()

                 result.data.list.map(item =>{
                   if(item.pics.length >0){
                     item.pics =  item.pics.slice(0,4)
                   }
                })

                this.dynamicList = result.data.list
               
              }
            }else{
              const result = await findAllCollectByUserId()
              if(result.code === 0 ){
                this.$toast.clear()
                 result.data.list.map(item =>{
                   if(item.pics.length >0){
                     item.pics =  item.pics.slice(0,4)
                   }
                })
                this.dynamicList = result.data
               
              }
            }
         }
      },
      
      created(){
        window.onClickLeft = this.onClickLeft
        if(sessionStorage.getItem("tabType")){
            this.active = parseInt(sessionStorage.getItem("tabType"))
            sessionStorage.removeItem("tabType")
        }
        if(this.$route.query.tabType){
            this.active = parseInt(this.$route.query.tabType)
        }
        this.getData()
        this.getinfo()
      }
      
  }
</script>

<style lang="scss" scoped>
.dynamic{
    ::v-deep .van-nav-bar__text{
      color: #333333;
      font-size: 15px;
      font-weight: 500;
    }
    .right{
      position: relative;
      .rightIcon{
        height: 20px;
        width: 20px;
      }
      .redIcon{
        height: 10px;
        width: 10px;
        background: red;
        border-radius: 50%;
        position: absolute;
        right: -7px;
        top: -3px;
      }
    }
    
    ::v-deep .van-nav-bar .van-icon{
      color: #333333;
    }
    .rightColor{
      color: #3783f3!important;
    }
    ::v-deep .van-nav-bar__title{
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
    ::v-deep .van-nav-bar__content{
      height: 57px;
    }
    ::v-deep .van-tabs__line{
      background-color: #3783F3;
      width: 9px;
      height: 2px;
     
    }
    ::v-deep .van-tabs__nav{
      box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);
    }
    .more{
      position: absolute;
      right: 10px;
      top: 25px;
      width:13px;
      height: 13px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .morePopup{
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      li{
        flex: 1;
        span{
          display: inline-block;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          transform: translateY(-4px);
        }
      }
    }
  .kong{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 500px;
       img{
        width: 139px;
        height: 112px;
       }
       p{
        color: #999999;
        font-size: 12px;
        font-weight: 500;
        margin-top: 10px;
       }
  }
}
.popup-operation{
  height: 228px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.popup_item{
  font-size: 14px;
  margin-left: 20px;
  margin-top: 20px;
  flex: 1;
}
.popup_item > img{
  width: 20px;
  height: 20px;
   transform: translateY(-2px);
}
.popup_item > span{
  margin-left: 20px;
}
.popup_active{
  height:20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .active_item{
    font-size: 14px;
    margin-left: 20px;
    margin-top: 20px;
    flex: 1;
    img{
      width: 20px;
      height: 20px;
       transform: translateY(-2px);
    }
    span{
      margin-left: 20px;
    }
  }
}
</style>